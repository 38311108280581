@import url('https://fonts.googleapis.com/css2?family=Cairo+Play:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@400&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root {
    --bg: #1b2021;
    --text: #ffffff;
    --disable: #b7b5b3;

    --primary: #82AEFF;
    --secondary: #293853;
    --accent: #68AC75;
}

html {
    scroll-behavior: smooth;
}

body {
    position: relative;

    font-family: 'Cairo', sans-serif;

    background-color: var(--bg);
    color: var(--text);
}

h1 { font-size: 9rem; font-family: 'Cairo Play', sans-serif; }
h2 { font-size: 7.5rem; font-family: 'Cairo Play', sans-serif; }
h3 { font-size: 4.5rem; min-height: 10rem; }
h4 { font-size: 3.5rem; }
h5 { font-size: 2.5rem; }
p { font-size: 1.5rem; text-align: justify;}
strong { font-size: 3rem; color: var(--disable); }

a {
    color: var(--text);
}

img {
    height: 100%;
    width: 100%;
}

.body--container {
    width: 80%;
    margin: 0 auto;
}

.hidden {
    display: none;
}

section {
    height: 100%;
    margin-bottom: 20vh;
}
