/* Mobile Devices */
@media screen and (max-width: 1023px) {
    .body--container {
        width: 85%;
    }

    .navbar {
        display: none;
    }

    .mobile-menu-icon {
        display: block;
    }
}

/* Tablet */
@media screen and (min-width: 768px) and (max-width: 1023px) {
    h1 { font-size: 6em; font-family: 'Cairo Play', sans-serif; }
    h2 { font-size: 4em; font-family: 'Cairo Play', sans-serif; }
    h3 { font-size: 2.5em; }
    h4 { font-size: 1.8em; }
    h5 { font-size: 1.4em; }
    p { font-size: 1.2em; text-align: justify; }
    strong { font-size: 2.5em; color: var(--disable); }
}

/* Mobile phone */
@media screen and (max-width: 767px) {
    h1 { font-size: 80px; font-family: 'Cairo Play', sans-serif; }
    h2 { font-size: 58px; font-family: 'Cairo Play', sans-serif; }
    h3 { font-size: 32px; }
    h4 { font-size: 24px; }
    h5 { font-size: 25px; }
    p { font-size: 18px; text-align: justify; }
    strong { font-size: 42px; color: var(--disable); }
}