@import "./default.css";

.section--title {
    margin-bottom: 5vh; 
    font-weight: 700;
    line-height: 1;
}

.highlightText {
    font-family: 'Cairo Play', sans-serif;
    font-weight: 700;
}

.navbar {
    position: fixed;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    transition: all 0.5s ease-in-out;
}

.navbar ul {
    list-style: none;
    padding: 0;
}

.navbar li {
    margin: 10px;
    background-color: var(--disable);
    transform: translateX(95%);
    transition: all 0.5s ease-in-out;
}

.navbar li.current {
    background-color: var(--primary);
    transition: all 0.5s ease-in-out;
}

.navbar li:hover {
    transform: translateX(10%);
    /* background-color: rgb(241 90 41); */
    background-color: var(--accent);
}

.navbar li a {
    opacity: 0;
    padding: 5px;
    text-decoration: none;
    color: var(--text);
    transition: all 0.5s ease-in-out;
}

.navbar li:hover a {
    opacity: 1;
    transition: all 0.5s ease-in-out;
}

.mobile-menu-icon {
    display: block;
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 1000;
    cursor: pointer;
}

.mobile-menu-icon .bar {
    width: 30px;
    height: 4px;
    background-color: var(--disable);
    margin: 5px 0;
    transition: 0.4s;
}

.mobile-menu-icon.change .bar:nth-child(1) {
    transform: rotate(-45deg) translate(-5px, 5px);
}

.mobile-menu-icon.change .bar:nth-child(2) {
    opacity: 0;
}

.mobile-menu-icon.change .bar:nth-child(3) {
    transform: rotate(45deg) translate(-5px, -5px);
}

.mobile-navbar {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    background-color: #f0f0f0;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateX(100%);
    opacity: 0;
    pointer-events: none;
    overflow: hidden;
}

.mobile-navbar ul {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: center;
}

.mobile-navbar ul li {
    margin: 20px 0;
}

.mobile-navbar ul li a {
    font-size: 2rem;
    color: var(--bg);
    text-decoration: none;
    opacity: 1;
}

.hero {
    height: 100svh;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    pointer-events: none;

    font-family: 'Cairo Play', sans-serif;
    line-height: 1;
}

.about p {
    /* width: 75%; */
    font-size: 20px;
    margin-bottom: 5vh;
    -webkit-hyphens: none;
    -moz-hyphens:    none;
    -ms-hyphens:     none;
    hyphens:         none;
}

.bubble--container {
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: stretch;
}

.bubble {
    margin: 10px;
    padding: 5px 25px;
    flex: 1 1 auto;
    background-color: var(--accent);
    border-radius: 50px;
    color: var(--bg);
    font-size: 1.2em;
    font-weight: 700;
}

.bubble p {
    text-align: center;
}

.bubble:nth-child(4n) {
    background-color: rgb(251 195 64);
}

.bubble:nth-child(4n+1) {
    background-color: rgb(39 170 225);
}

.bubble:nth-child(4n+2) {
    background-color: rgb(242 145 198);
}

.bubble:nth-child(4n+3) {
    background-color: rgb(241 90 41);
}

.btn {
  padding: 1em 2em;
  border: 0.15em solid #fff;
  color: #fff;
  text-decoration: none;
  font-weight: 700;
  transition: background 250ms;
}

.btn:hover {
  background: rgba(0, 0, 0, 0.6);
}

.contact--form {
    /* width: 80%; */
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.contact--form input, .contact--form button {
    margin-top: 10px;
    font-family: 'Cairo', sans-serif;
    font-weight: 700;
    font-size: 1em;
    border: none;
}

.contact--form input {
    width: 40%;
    color: var(--text);
    background-color: transparent;
    border-bottom: 4px solid white;
}

.contact--form input:focus {
    border-bottom: 4px solid var(--accent);
    outline: none !important;
}

.contact--form button {
    color: var(--accent);
    background:
    linear-gradient(var(--accent) 0 0) no-repeat
    calc(200% - var(--i, 0) * 100%) 100% / 200% calc(100% * var(--i, 0) + .08em);
    transition: .3s calc(var(--i, 0) * .3s), background-position .3s calc(.3s - calc(var(--i, 0) * .3s))
}

.contact--form button:hover {
    --i: 1;
    color: var(--text);
}

/* --- Footer --- */
#end {
    position: relative;
    height: 30vh;
    width: 100%;
    margin-top: 25vh;
}

#end .curve {
    position: absolute;
    width: 100%;
    bottom: 100%;
    left: 0;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

#end .curve .shape-fill {
    fill: #27292F;
}

/* --- Liquid bubbles --- */
#end .bubble-container {
    height: 100%;
    width: 100%;
    background-color: #27292F;
    position: relative;
    bottom: 0px;
}

#end .footer-container {
    height: 100%;
    width: 100%;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
    position: relative;
    padding-bottom: 40px;
}

#end .social {
    font-size: 25px;
    display: flex;
    gap: 20px;
    margin: auto 0;
}

#end .social-icon {
    display: flex;
    align-items: center;
    gap: 10px;
}

#end .social-icon a {
    color: white;
    text-decoration: none;
}

#end .copyright {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    padding-bottom: 20px;
    text-align: center !important;
    font-size: 14px;
}